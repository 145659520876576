$(document).ready(function(){
    $("body").on("click", ".catalog-search", function() {
        var target = $(this).data().url;
        var data = $(".catalog-search-query").val().trim();
        if (data.length == 0) {
            alert("Please enter a keyword to search for");
            return false;
        }
        window.location.href = target + s_encode(data);
    });
    $(".catalog-search-query").keypress(function (e) {
        var key = e.which;
        if(key == 13)  // the enter key code
        {
            $(".catalog-search").click();
            return false;
        }
    });
});

$(document).ready(function(){
    $("body").on("click", ".catalog-searchb", function() {
        var target = $(this).data().url;
        var data = $(".catalog-search-queryb").val().trim();
        if (data.length == 0) {
            alert("Please enter a keyword to search for");
            return false;
        }
        window.location.href = target + s_encode(data);
    });
    $(".catalog-search-queryb").keypress(function (e) {
        var key = e.which;
        if(key == 13)  // the enter key code
        {
            $(".catalog-searchb").click();
            return false;
        }
    });
});