import Swiper, { Autoplay } from 'swiper';
import 'swiper/scss';

Swiper.use([ Autoplay]);

if (document.querySelector('#popular-products')){
    const productsCarousel = new Swiper('#popular-products .swiper', {
        slidesPerView: 2,
        spaceBetween: 15,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            992: {
                slidesPerView: 4
            }
        },
    });
}
