import Swiper, {Autoplay, EffectFade} from 'swiper';
import 'swiper/scss';
import 'swiper/scss/effect-fade';

Swiper.use([Autoplay, EffectFade]);
if (document.querySelector('#hero')) {
    const swiper = new Swiper('#hero .swiper-container', {
        centeredSlides: true,
        loop: false,
        speed: 2000,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 8000,
        },
        on: {
            init: function () {
                let swiperSlides = document.querySelector('#hero .swiper-slide-active');
                swiperSlides.classList.add("swiper-slide-scale");
            }
        }
    });

    swiper.on('slideChangeTransitionStart', function () {
        const slides = document.querySelectorAll('#hero .swiper-slide');
        slides[swiper.activeIndex].classList.add("swiper-slide-scale");
        setTimeout(function () {
            slides[swiper.previousIndex].classList.remove("swiper-slide-scale");
        }, 2000)
    });
}


