$(document).ready(function () {


    if ($('.imageGallery').length > 0) {


        $('.imageGallery').lightSlider({
            gallery: false,
            auto: false,
            item: 3,
            loop: true,
            pauseOnHover: true,
            enableTouch: true,

            slideMargin: 0,
            enableDrag: true,
            autoWidth: true,
            currentPagerPosition: 'left',
            mode: "slide",
            useCSS: true,
            cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
            easing: 'linear', //'for jquery animation',////

            prevHtml: '<button class="w3-button w3-black w3-display-left noselection" onclick="plusDivsnew(-1)">❮</button>',
            nextHtml: '<button class="w3-button w3-black w3-display-right noselection" onclick="plusDivsnew(1)">❯</button>',

            speed: 400, //ms'
            pause: 2000
            /*onSliderLoad: function(el) {
                el.lightGallery({
                    selector: '#imageGallery .lslide'
                });
            }
            */
        });
    }

    if ($('#imageGallery').length > 0) {

        var slider = $('#imageGallery').lightSlider({
            gallery: false,
            auto: false,
            item: 3,
            loop: true,
            pauseOnHover: true,
            enableTouch: true,

            slideMargin: 0,
            enableDrag: true,
            autoWidth: true,
            currentPagerPosition: 'left',
            mode: "slide",
            useCSS: true,
            cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
            easing: 'linear', //'for jquery animation',////
            controls: false,


            speed: 400, //ms'
            pause: 2000
            /*onSliderLoad: function(el) {
                el.lightGallery({
                    selector: '#imageGallery .lslide'
                });
            }
            */
        });

        $('#goToPrevSlide').on('click', function () {
            slider.goToPrevSlide();
        });
        $('#goToNextSlide').on('click', function () {
            slider.goToNextSlide();
        });

    }

    var currentActiveTab = null;
    $("body").on("click", ".nav-tabs button", function () {

        // is current bootstrap5 tab active, is it not the first click to open,and are we clickin on the same tab as the current active tab?
        if ($(this).hasClass('active') && currentActiveTab == $(this).text())
        {
           $(".tab-pane").removeClass('active');
           $(".nav-tabs button").removeClass('active');
           $(".tab-pane").removeClass('show');
            currentActiveTab = null;
        } else {
            currentActiveTab = $(this).text();
        }

    });


    $("body").on("click", ".facet-input", function () {
        //target = $(this).parent().attr("href");
        target = $(this).data().url;
        window.location.href = target;
    });
    $('body').on("change", "#pp", function () {
        target = $(this).find(':selected').data().href;
        window.location.href = target;
    });
    $('body').on("change", "#sort", function () {
        target = $(this).find(':selected').data().href;
        window.location.href = target;
    });
    $('body').on("click", ".sorting__link", function () {
        target = $(this).data().href;
        window.location.href = target;
        return false;
    });


    // when the year drodown has a change, add the product to the cart
    $('body').on("change", ".year-range", function() {
        $("#add-to-order-" + $(this).data().sku).trigger( "click" );
    });

    // when the add to order button is clicked on a product
    $('body').on("click", ".add-to-order", function () {

        if ($("#yearRange-" + $(this).data().sku).length > 0 && $("#yearRange-" + $(this).data().sku).val() == '' ) {
            alert('Please select a vehicle year');
            return false;
        }

        var productId = $(this).data().productid;
        var sku = $(this).data().sku;
        var url = $(this).data().url;
        var category = $(this).data().category;
        var subcategory = $(this).data().subcategory;
        var currencyCode = $(this).data().currencyCode;
        var title = $(this).data().productitle;
        var price = $(this).data().price;
        var brand = $(this).data().brand;
        var position = $(this).data().position;
        var product = $(this).data().productname;
        var feedId = $(this).data().feedid;

        var make = $(this).data().make;
        var model = $(this).data().model;
        if ($("#yearRange-" + $(this).data().sku).length > 0) {
            var year = $("#yearRange-" + $(this).data().sku).val();
        } else {
            var year = $(this).data().year;
        }
        // if we have a year range, then the vehicle text doesn't contain the year, so we need to add it here, so it displays in the cart properly
        if ($("#yearRange-" + $(this).data().sku).length > 0) {
            var vehicleText = year + " " + $(this).data().vehicle;
        } else {
            var vehicleText = $(this).data().vehicle;
        }
        var vehiclePosition = $(this).attr('data-vehicle-position');

        var metadata = {
            make: make,
            model: model,
            year: year,
            vehicleText: vehicleText,
            vehiclePosition: vehiclePosition
        };

        var qty = $(".qty[data-productid='" + productId + "']").val();

        if (isNaN(qty) || !isInt(qty) || qty <= 0) {
            alert("Please enter a valid quantity between 1 and 99");
            return false;
        }

        $(this).attr("disabled", "disabled");
        $(this).css("background-image", "none");
        $(this).css("background-color", "#efefef");
        $(this).css("border", "1px solid #fff");
        $(this).css("color", "#333");
        $(this).html("<i class='fa fa-check'></i> Added to Cart");

        var data = {
            product_id: productId,
            sku: sku,
            qty: qty,
            name: product,
            feedId: feedId,
            category: category,
            subcategory: subcategory,
            currencyCode: currencyCode,
            brand: brand,
            title: title,
            price: price,
            position: position,
            metadata: metadata
        };

        addToCart(data, url);

        // create a timeout to reset the button after 3 seconds
        setTimeout(function () {
            $(this).removeAttr("disabled");
            $(this).css("background-color", "#273B91");
            $(this).css("border", "0");
            $(this).css("color", "#fff");
            $(this).html("Add to Cart");
        }
            .bind(this), 3000);


        return false;
    });


/*

    // when the add to order button is clicked on a product
    $('body').on("click", ".add-to-order", function () {
        var productId = $(this).data().productid;
        var sku = $(this).data().sku;
        var url = $(this).data().url;
        var category = $(this).data().category;
        var title = $(this).data().productitle;
        var price = $(this).data().price;
        var brand = $(this).data().brand;
        var position = $(this).data().position;
        var product = $(this).data().productname;

        var make = $(this).data().make;
        var model = $(this).data().model;
        var year = $(this).data().year;
        var vehicleText = $(this).data().vehicle;
        var vehiclePosition = $(this).attr('data-vehicle-position');

        var metadata = {
            make: make,
            model: model,
            year: year,
            vehicleText: vehicleText,
            vehiclePosition: vehiclePosition
        };

        var qty = $(".qty[data-productid='" + productId + "']").val();

        if (isNaN(qty) || !isInt(qty) || qty <= 0) {
            alert("Please enter a valid quantity between 1 and 99");
            return false;
        }

        $(this).attr("disabled", "disabled");
        $(this).css("background-image", "none");
        $(this).css("background-color", "#efefef");
        $(this).css("border", "1px solid #fff");
        $(this).css("color", "#333");
        $(this).text("Please Wait...");

        var data = {
            product_id: productId,
            sku: sku,
            qty: qty,
            name: product,
            category: category,
            brand: brand,
            title: title,
            price: price,
            position: position,
            metadata: metadata
        };
        addToCart(data, url);

        return false;
    });
*/


    $("body").on("click", ".products-list__more", function () {

        if ( $("#next-page-url").length > 0 ) {
            var url = $("#next-page-url").data().url;
            url += "&partial=1";

            $.ajax({
                url: url,
                type: "get",
                cache: false,
                data: { partial : true },
                dataType: "html",
                success: function (data) {
                    // remove the original next-page-url, as a new one will be inserted with the updated page number
                    $("#next-page-url").remove();
                    // remove the more button, as a new one will be inserted
                    $(".products-list__more").remove();
                    // insert the new records to the last container (that was just inserted)
                    $(".next-product-container").filter(":last").append(data);
                    // update the number of products
                    $(".num-products").html( $(".product-line").length );
                }
            });
        }
    });


    $("body").on("click", ".toggle-hidden-posts", function () {

        $(this).parents('.form-post-container').find(".minimized").toggle();

        $toggle = $(this).find(".text-toggle");
        $toggle.toggleClass('visible');

        if ($toggle.hasClass('visible')) {
            $toggle.text($toggle.data().textb);
        } else {
            $toggle.text($toggle.data().texta);
        }


    });

    $("body").on("click", ".show-side-nav", function () {

        var offmsg = $(this).data().offmsg;
        var onmsg = $(this).data().onmsg;
        $button = $(this);

        $(".facets-cont").toggle();
        $(".facets-cont").toggleClass('visible')

        if ($(".facets-cont").hasClass('visible')) {
            $button.text(onmsg);
        } else {
            $button.text(offmsg);
        }
    });

    $("body").on("click", ".toggle-hide-by-default", function () {

        $(".hide-by-default").toggleClass('visible');

        $toggle = $(this);
        $toggle.toggleClass('visible');

        chevronUp = '<i class="fas fa-chevron-up"></i>';
        chevronDown = '<i class="fas fa-chevron-down"></i>';

        if ($toggle.hasClass('visible')) {
            $toggle.html(chevronUp + " Show Less " + $toggle.data().name);
        } else {
            $toggle.html(chevronDown + " Show More " + $toggle.data().name);
        }

    });

    $("body").on("click", ".expand", function () {

        $(".facets-cont").animate({width: "600"}, "fast");
    });


    /*
        $("body").on("mouseover", ".facets-cont", function() {
            $(".facets-cont").hover(function() {
             //   $(this).closest(".facets-cont").css("z-index", 1);

                // this is where the popping out effect happens
                $(this).animate({ width: "100%" }, "fast");

            }, function() {
              //  $(this).closest(".facets-cont").css("z-index", 0);
               // $(this).animate({ width: "200"  }, "fast");

                // this is where the popping out effect happens
                $(this).animate({ width: "100vw" }, "fast");

            });
        });*/
});


function growFacets() {
    var t,
        $self = $(this),
        $contentBox = $self.find('.facets-cont'),
        newHeight = $contentBox.innerHeight() * 3

    $self.hover(function () {
        clearTimeout(t);
        $contentBox.stop().animate({
            'height': newHeight
        }, {
            step: function () {
                $contentBox.css("overflow-y", "auto");
            }
        });
    }, function () {
        clearTimeout(t);
        t = setTimeout(function () {
            $contentBox.stop().animate({
                'height': 100
            }, {
                step: function () {
                    $contentBox.css("overflow-y", "auto");
                }
            });
        }, 300)
    });
}


(function ($) {

})(jQuery);