/*
class headerMenu {
    header   = document.querySelector('#header');
    menuComponent = document.querySelectorAll('#header .dropdown-menu.main-menu-big');
    constructor() {
        if(this.header && this.isCheckout == null) {

            if (this.menuComponent.length > 0) {
                this.initMenuHover();

            }
        }
    }


    initMenuHover() {
        alert('adsa')
        document.addEventListener('DOMContentLoaded', function () {
            var dropdowns = document.querySelectorAll('.dropdown-menu.main-menu-big');
            var hoverDelay = 500; // Delay in milliseconds (500ms = 0.5 seconds)
            var hoverTimeout;

            dropdowns.forEach(function(dropdown) {
                dropdown.addEventListener('mouseover', function() {
                    // Clear any existing timeout to avoid multiple triggers
                    clearTimeout(hoverTimeout);

                    // Set a new timeout
                    hoverTimeout = setTimeout(() => {
                        this.querySelector('.dropdown-menu.main-menu-big').style.display = 'block';
                    }, hoverDelay);
                });

                dropdown.addEventListener('mouseout', function() {
                    // Clear the timeout when mouse leaves the element
                    clearTimeout(hoverTimeout);

                    // Hide the dropdown immediately
                    this.querySelector('.dropdown-menu.main-menu-big').style.display = '';
                });
            });
        });

    }
}
window.onload = ()=>{
    const newHeaderMenu = new headerMenu();
}
*/


document.addEventListener('DOMContentLoaded', function () {
    var dropdowns = document.querySelectorAll('.nav-item.dropdown');
    var hoverDelay = 300; // Delay in milliseconds
    var hoverTimeout;

    dropdowns.forEach(function(dropdown) {
        var dropdownMenu = dropdown.querySelector('.dropdown-menu.main-menu-big'); // Specific to each dropdown

        dropdown.addEventListener('mouseover', function() {
            // Clear any existing timeout to avoid multiple triggers
            clearTimeout(hoverTimeout);

            // Set a new timeout
            hoverTimeout = setTimeout(() => {
                dropdownMenu.style.display = 'block';
            }, hoverDelay);
        });

        dropdown.addEventListener('mouseout', function(event) {
            // Check if the mouse is moving to a child of the dropdown
            var movingTo = event.relatedTarget;
            if (!dropdown.contains(movingTo)) {
                // Clear the timeout when mouse leaves the dropdown and its children
                clearTimeout(hoverTimeout);

                // Hide the dropdown immediately
                dropdownMenu.style.display = '';
            }
        });
    });
});

