class header {
    header   = document.querySelector('#header');
    isCheckout   = document.querySelector('.checkout-header');
    searchComponent = document.querySelectorAll('#header .searchInput');
    searchInput = document.querySelectorAll('#header .searchInput .catalog-search-query');

    constructor() {
        if(this.header && this.isCheckout == null) {


           //this.offCanvas();
            //this.reveal();
            //this.navScrollAction();
            if (this.searchComponent.length > 0) {
              //  this.search();
                //this.searchButton();
                this.vehicleSelected();

            }
        }
    }
/*
    offCanvas() {
        const myOffcanvas = document.getElementById('offcanvasNavbar');
        if (myOffcanvas != null) {
            const toggler = document.querySelectorAll('.navbar-toggler');
            myOffcanvas.addEventListener('show.bs.offcanvas', () => {
                toggler.forEach((e) => {
                    e.classList.add("open");
                });
            });
            myOffcanvas.addEventListener('hide.bs.offcanvas', () => {
                toggler.forEach((e) => {
                    e.classList.remove("open");
                });
            });
        }
    }

    reveal() {
        const navDesktop = document.querySelector('#nav-desktop');
        if (navDesktop != null) {
            const navWidth = navDesktop.offsetWidth - 150;
            const navbarNav = document.querySelector('#navbarNav');
            const navbarNavChild = navbarNav.querySelectorAll('.nav-link span');
            const navClone = navbarNav.cloneNode(true);
            const child = navClone.querySelectorAll('.nav-link span');
            let navCloneWidth;
            navbarNav.style.cssText = "visibility:hidden";
            document.querySelector("body").appendChild(navClone);
            navClone.style.cssText = "position: absolute; top: 0; left: 150px;visibility:hidden;white-space: nowrap;";
            child.forEach(function (e) {
                e.classList.remove('reveal');
            });
            navCloneWidth = navClone.offsetWidth + 100;
            if (navCloneWidth <= navWidth) {
                navbarNavChild.forEach(function (e) {
                    e.classList.remove('reveal');
                });
                navbarNav.style.cssText = "visibility:visible";
            } else {
                navbarNav.style.cssText = "visibility:visible";
            }
            navClone.remove();
        }
    }

    navScrollAction() {
        const navDesktop = document.querySelector('#nav-desktop');
        if (navDesktop != null) {
            if (!navDesktop.classList.contains('-bk-solid')) {
                window.onscroll = function () {
                    let top = window.pageYOffset || document.documentElement.scrollTop;
                    if (top > 200) {
                        document.getElementById('nav-desktop').style.background = "#4b0608";
                    } else {
                        document.getElementById('nav-desktop').style.background = "";
                    }
                };
            } else {
                document.getElementById('nav-desktop').style.background = "#4b0608";
            }
        }
    }

    search() {
        const self = this;
        this.searchComponent.forEach(function (component) {
            component.addEventListener('click', (e) => {
                e.stopPropagation();
                component.classList.add("focus");
            });
            window.addEventListener('click', () => {
                component.classList.remove("focus");
            });
            self.searchInput.forEach(function (input) {
                input.addEventListener('keypress', (e) => {
                    e.stopImmediatePropagation();
                    const key = e.which;
                    const url = e.target.parentNode.parentNode.dataset.url;
                    const value = e.target.value;
                    if (key == 13)  // the enter key code
                    {
                        console.log(url+ ' 1 '+ value)
                        self.searchSubmit(url,value);
                    }
                });
            });
        });
    }

    searchButton() {
        const button = document.querySelectorAll('#header .searchIcon');
        const self = this;
        button.forEach(function (btn) {
            btn.addEventListener('click', (e) => {
                const url = e.target.closest('.searchInput').dataset.url;
                const value = e.target.closest('.searchInput').querySelector('.catalog-search-query').value;
                console.log(url+ ' 2 '+ value)
                self.searchSubmit(url,value);
            });
        });
    }

    searchSubmit(url,value) {
        const target = url;
        const data = value;
        if (data.length == 0) {
            alert("Please enter a keyword to search for");
            return;
        }
       window.location.href = target + s_encode(data);
    }*/

    vehicleSelected() {
        const vehicle = document.querySelectorAll('#header .searchInput .selectedVehicle');
        const vehicleSelected = this.searchComponent[0].dataset.vehicle;
        const noVehicle = ()=>{
            vehicle.forEach(function (vhcl) {
                vhcl.querySelector('.vehicle-text').textContent = '';
                vhcl.style.display = 'none';
            });
        }
        if (vehicleSelected != '') {
            vehicle.forEach(function (vhcl) {
                vhcl.querySelector('.vehicle-text').textContent = vehicleSelected;
                vhcl.style.display = null;
            });
        } else {
            noVehicle();
        }
        vehicle.forEach(function(vhcl){
            const rm = vhcl.querySelector('.remove');
            rm.addEventListener('click', ()=>{
                noVehicle();
            });

        });
    }
}
window.onload = ()=>{
    const newHeader = new header();
}