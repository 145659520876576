class Sidebar {
    sidebar = document.querySelector('.main-content-sidebar');

    constructor() {
        if(this.sidebar) {
            this.toggle();
        }
    }

    toggle(){
        const button = document.querySelector('[data-js="sidebar-toggle"]');
        const sidebar = document.querySelector('.sidebar');
        const close = document.querySelector('[data-js="sidebar-toggle-close"]');
        button.addEventListener('click', ()=>{
            sidebar.classList.add('show');
        });
        close.addEventListener('click', ()=>{
            sidebar.classList.remove('show');
        });
    }

}
const sidebar = new Sidebar();