import Swiper, { Autoplay } from 'swiper';
import 'swiper/scss';

Swiper.use([ Autoplay]);

if (document.querySelector('#generations-selector')){
    const productsCarousel = new Swiper('#generations-selector .image-slider', {
        slidesPerView: 3,
        spaceBetween: 3,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true, // Optional, for dynamic bullets
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            576: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 3
            },
            992: {
                slidesPerView: 4
            },
            1900: {
                slidesPerView: 5
            },
            2290: {
                slidesPerView: 6
            }

        },
    });
}
