import Swiper, { Autoplay, Navigation, Pagination  } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

Swiper.use([ Autoplay, Navigation, Pagination]);

if (document.querySelector('#all-categories')){
    const productsCarousel = new Swiper('#all-categories .swiper', {
        slidesPerView: 2,
        spaceBetween: 15,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            type: 'bullets',
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            992: {
                slidesPerView: 4
            }
        },
    });
}
