import '../styles/main.scss';
//import 'bootstrap';
//import "bootstrap/dist/js/bootstrap.bundle";
//global.jQuery = global.$ = require('jquery');

import 'jquery-unveil';
import 'gasparesganga-jquery-loading-overlay';
import '@fancyapps/fancybox';
import 'bootstrap/js/dist/tooltip';
import 'slick-carousel';


import './helpers';


import './components/lib';
import './components/main';
import './components/search';
import './components/vehicle-widget';
import './components/product-list';
import './components/cart';
import './components/brands';
import './components/recaptcha';
import './components/tooltip';
import './components/homepage-categories';

import './old-components/header';
import './old-components/headerMenu';
import './old-components/hero';
import './old-components/popularProducts';
import './old-components/generationsSelector';
import './old-components/sidebar';
import './old-components/vehicle-select'

/**
 *
 *
 *     Dynamic Imports: Split code via inline function calls within modules
 *
 *     This will allow you to break your code logically into modules tied to the screens so only the required libraries will be loaded. For more info about dynamic imports, you can check the official documentation. https://webpack.js.org/guides/code-splitting/
 *
 */


function getPartTypeComponent() {
    return import(
        /* webpackChunkName: "user/part-name" */
        './components/part-name')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getCategoryComponent() {
    return import(
        /* webpackChunkName: "user/part-name" */
        './components/part-name')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getProductDetailComponent() {
    return import(
        /* webpackChunkName: "catalog/product-detail" */
        './components/catalog/product-detail')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getUserAddressComponent() {
    return import(
        /* webpackChunkName: "user/user-address" */
        './components/user/address')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getUserOrdersComponent() {
    return import(
        /* webpackChunkName: "user/user-orders" */
        './components/user/orders')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getCheckoutComponent() {
    return import(
        /* webpackChunkName: "checkout/checkout" */
        './components/checkout/checkout')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');

}

function getCheckoutBillingComponent() {
    return import(
        /* webpackChunkName: "checkout/checkout-billing" */
        './components/checkout/checkout-billing')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getCheckoutBillingProvider(paymentProviderName) {
    //var paymentProviderName = document.getElementsByClassName("payment_provider").value;
    return import(
        /* webpackChunkName: "checkout/payment-providers/payment-provider" */
        `./components/checkout/payment-providers/${paymentProviderName}`)
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the component');
}

function getLiveSearchComponent() {
    return import(
        /* webpackChunkName: "user/user-address" */
        './components/search/live')
        .then(({default: _}) => {
        })
        .catch((error) => 'An error occurred while loading the Live Search component');
}

if (document.querySelector('.section-part-type')) {
    getPartTypeComponent();
}
if (document.querySelector('.section-category')) {
    getCategoryComponent();
}
if (document.querySelector('.section-product-detail')) {
    getProductDetailComponent();
}
if (document.querySelector('.section-user-orders')) {
    getUserOrdersComponent();
}
if (document.querySelector('.section-user-address')) {
    getUserAddressComponent();
}

// only include if we are on the checkout billing page
if (document.querySelector('.section-checkout-billing')) {
    getCheckoutBillingComponent();
    getCheckoutBillingProvider(document.querySelector('.payment_provider').value);
}

// only include if we are on the checkout address page
if (document.querySelector('.section-checkout-address')) {
    getCheckoutComponent();
}

getLiveSearchComponent();




//import './components/header';
//import './components/hero';
//import './components/popularProducts';
//import './components/vehicle-select';
//import './components/brands';
//import './components/products';
//import './components/newsletter';
//import './components/sidebar';