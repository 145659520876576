class vehicleSelect {
    //vehicles        = [];
    vehicleSelect   = document.querySelector('.vehicle-selector-dropdown');
    //vehicleSelected = this.vehicleSelect.querySelectorAll('[data-js="vehicle"]');
    dropdown        = this.vehicleSelect.querySelector('[data-js="vehicle-dropdown"]');
    //body            = this.vehicleSelect.querySelector('.body');

    constructor() {
        if (this.vehicleSelect) {
            //this.onFormSubmit();
            //this.goToSelected();
            //this.removeVehicle();
            this.onSelectChange();
        }
    }

    onFormSubmit() {
        const form = this.vehicleSelect.querySelector('[name="vehicle-selection"]');
        let vehicle;
        let year = form.querySelector('[data-js="year"]');
        let make = form.querySelector('[data-js="make"]');
        let model = form.querySelector('[data-js="model"]');
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            vehicle = year.value + ' ' + make.value + ' ' + model.value;
            this.vehicles.push(vehicle);
            this.addVehicle(vehicle);
        });
    }

    addVehicle(vehicle) {
        const addVehicle = this.vehicleSelect.querySelector('[data-js="add-vehicle"]');
        let newOption = new Option(vehicle, vehicle);
        this.dropdown.add(newOption, undefined);
        this.dropdown.value = vehicle;
        this.vehicleSelected.forEach((e) => {
            e.innerHTML = vehicle;
        });
        this.vehicleSelect.classList.add('-selected');
        addVehicle.addEventListener('click', () => {
            this.vehicleSelect.classList.remove('-selected');
        });
    }

    goToSelected() {
        const myGarage = this.vehicleSelect.querySelector('.enter-vehicle .my-garage-button');
        if (myGarage != null) {
            myGarage.addEventListener('click', () => {
                if (!!this.dropdown.children.length) {
                    this.vehicleSelect.classList.add('-selected');
                }
            });
        }
    }

    removeVehicle() {
        const remove = this.vehicleSelect.querySelector('[data-js="remove"]');
        if (remove != null) {
            let selected, newSelected;
            remove.addEventListener('click', () => {
                for (let i = 0; i < this.dropdown.options.length; i++) {
                    if (this.dropdown.options[i].selected) {
                        selected = this.dropdown.options[i].getAttribute('value');
                    }
                }
                this.dropdown.querySelector('option[value="' + selected + '"]').remove();
                if (this.dropdown.options.length > 0) {
                    newSelected = this.dropdown.options[0].value;
                    this.dropdown.value = newSelected;
                } else {
                    newSelected = '';
                    this.vehicleSelect.classList.remove('-selected');
                }
                this.vehicleSelected.forEach((e) => {
                    e.innerHTML = newSelected;
                });
            });
        }
    }

    onSelectChange() {
        let selected;
        if (this.dropdown != null) {
            this.dropdown.addEventListener('change', () => {
                for (let i = 0; i < this.dropdown.options.length; i++) {
                    if (this.dropdown.options[i].selected) {
                        selected = this.dropdown.options[i].getAttribute('value');
                        console.log( this.dropdown.options[i].getAttribute('data-href') );
                        showLoading();
                        window.location.href = this.dropdown.options[i].getAttribute('data-href');
                    }
                }
                this.vehicleSelected.forEach((e) => {
                    e.innerHTML = selected;
                });
            })
        }
    }
}

if (document.querySelector('.vehicle-selector-dropdown')) {
    const newVehicleSelect = new vehicleSelect();
}